var OUtils;
var DateUtil;
var AddressUtil;
var StrUtil;
var ValidationUtil;
var FileUtil;
define("OUtils", ["js/DateTimeUtils", "js/OTranslate"], function(DateTimeUtils, OTranslate) {
	"use strict";
	
	/**Core JS Prototype changes: **/
	var lastLoaded;
	var cloudConnectHost;
	function isLocalhost() {
		return (""+window.location).indexOf("localhost") >= 0;
	}
		
	DateUtil = (function() {
		// i18n: need to consider how to translate these errors since they're used in a lot of places
		Date.create = function(year,month,day) {
			if (!month) throw OTranslate.t("invalid Date.create args", "OUtils.js.invalid_date_create_args");
			return new Date(year,month-1,day);
		};
		function parseISODate(s) {
			var D = new Date("2011-06-02T09:34:29+02:00");
			if (!D || +D !== 1307000069000) {//eg IE8
				var day, tz, rx = /^(\d{4}\-\d\d\-\d\d([tT ][\d:\.]*)?)([zZ]|([+\-])(\d\d):(\d\d))?$/, p = rx.exec(s) || [];
				if (p[1]) {
					day = p[1].split(/\D/);
					for (var i = 0, L = day.length; i < L; i++) {
						day[i] = parseInt(day[i], 10) || 0;
					}
					day[1] -= 1;
					day = new Date(Date.UTC.apply(Date, day));
					if (!day.getDate()) {
						return NaN;
					}
					if (p[5]) {
						tz = (parseInt(p[5], 10) * 60);
						if (p[6]) {
							tz += parseInt(p[6], 10);
						}
						if (p[4] == '+') {
							tz *= -1;
						}
						if (tz) {
							day.setUTCMinutes(day.getUTCMinutes() + tz);
						}
					}
					return day;
				}
				return NaN;
			} else {
				return new Date(s);//modern browsers support ISO
			}
		}
		Date.parseDateMidnight = function(dateStr) {
			if (!dateStr) {
				return null;
			}
			var tz = dateStr.match("T[0-9][0-9]");
			if (tz) {//parse ISO timezone info first
				dateStr = dateStr.substring(0, dateStr.indexOf(tz));
			}
			return this.parseDate(dateStr);
		};
		Date.parseDate = function(dateStr, allowLenientParse) {
			// allowLenientParse - see bug #8297 - leaving this optional parameter here in case there are field
			// examples that break with the stricter parsing introduced in this fix. Calling parseDate(<date>, true) will allow
			// the older parsing code.
			if (!dateStr || dateStr.length === 0) return null;
			if (dateStr === "--T::Z") { return null; }
			if (dateStr.match("T[0-9][0-9]")) {//parse ISO timezone info first
				dateStr = parseISODate(dateStr).format();
			}
			//Mar 5, 1982, yyyy/mm/dd or mm/dd/yyyy (strict 4 digit year parsing)
			if (dateStr.match(/.* [0-9]+, \d{4}$/) || dateStr.match(/^\d{4}\/[0-9]+\/[0-9]+$/) || dateStr.match(/^[0-9]+\/[0-9]+\/\d{4}$/)) { 
				return new Date(dateStr);
			} else if (allowLenientParse && (dateStr.match(/.* [0-9]+, [0-9]+/) || dateStr.match(/[0-9]*\/[0-9]*\/[0-9]*/))) { 
				// lenient parsing - allows 2 digit years, missing day/month values, stuff at the start/end of the string, etc. 
				return new Date(dateStr);
			}

		    var dateParts = dateStr.split('-');
		    try {
		    	if (!allowLenientParse && dateParts[0].length < 4) {
		    		throw OTranslate.t("Years must be 4 digits: {{dateStr}}", "OUtils.js.years_4_digits_warning", {dateStr: dateStr});
		    	}
		    	
			    var result = Date.create(parseInt(dateParts[0], 10), parseInt(dateParts[1], 10), parseInt(dateParts[2], 10));
			    // Check if year is more than 4 digits
				if (result.getFullYear() > 9999 || result.getFullYear() < 1000) {
					throw new Error(OTranslate.t("bad date format: {{dateStr}}", "OUtils.js.bad_date_format_warning", {dateStr: dateStr}));
				}
			    return result;
		    }
		    catch (e) {
		    	throw new Error(OTranslate.t("bad date format: {{dateStr}}", "OUtils.js.bad_date_format_warning", {dateStr: dateStr}));
		    }
		};
		Date.parseDateTime = function(date, timeString) {
			if (date === '') {
				return null;
			}
			var d = this.parseDate(date);

			var time = timeString.match(/(\d\d?)(?::?(\d\d))?\s*(pm?|am?)?/i);
			if (time === null) {
				return d;
			}
			var hour = parseInt(time[1]);
			var pm = (time[3] || ' ')[0].toUpperCase();
			var min = time[2] ? parseInt(time[2]) : 0;
			if (pm !== ' ' && (hour == 0 || hour > 12) || hour > 24 || min >= 60) {
				return null;
			}
			if ((pm === 'A' && hour === 12) || hour === 24) {
				hour = 0;
			}
			if (pm === 'P' && hour !== 12) {
				hour += 12;
			}

			d.setHours(hour);
			d.setMinutes(min);

			return d;
		};
		Date.prototype.year = function() { return this.getYear() + 1900; };
		Date.prototype.month = function() { return this.getMonth()+1; };
		Date.prototype.day = function() { return this.getDate(); };
		Date.prototype.yyyy_mm_dd = function() { return this.format("yy-mm-dd"); };
		Date.prototype.format = function(format, forceEnglish) {
			if (!format) format = "M d, yy";//standard Jan 1, 1982 format
			if (isNaN(this)) { console.error("bad date format"); return "" }
			var options = {};
			if (forceEnglish) {
				//"" will default to English
				options.dayNamesShort = $.datepicker.regional[""].dayNamesShort;
				options.dayNames =  $.datepicker.regional[""].dayNames;
				options.monthNamesShort =  $.datepicker.regional[""].monthNamesShort;
				options.monthNames =  $.datepicker.regional[""].monthNames;
			}
			return forceEnglish ? $.datepicker.formatDate(format, this, options) : $.datepicker.formatDate(format, this);
		};//uses the jquery datepicker date formatting syntax
		Date.prototype.addDays = function(days) {
		    var dt = new Date(this.valueOf());
		    dt.setDate(dt.getDate() + days);
		    return dt;
		};
		function format(date, dtFormat) {
			return date ? date.format(dtFormat) : "";
		}
		function numToStr(num, minDec) {
			var str = ""+num;
			while (str.length < minDec) { str = "0" + str; }
			return str;
		}
		function convertDateTimeZone(date, timeZoneString) {
			return new Date(date.toLocaleString("en-US", { timeZone: timeZoneString }));
		}
		function formatDateTimeWithTimeZone(dt, opts, timeZoneString) {
			// if there isn't a time zone provided, get the local device time zone
			if (timeZoneString == null) {
				timeZoneString = DateTimeUtils.getLocalTimeZone();
			}
			try {
				dt = convertDateTimeZone(dt, timeZoneString);
				return formatDateTime(dt, opts);
			} catch (error) {
				return DateTimeUtils.formatDateTimeInSiteTZ(dt, timeZoneString, OTranslate.t('MMM D, yyyy h:mm a', "OUtils.js.date_format_pattern"));
			}
		}
		// This takes a date-time and formats it into a string, eg "July 24th, 2022 11:20AM"
		// This should only be used for display purposes on the UI, since it formats it based on the UI client's timezone
		function formatDateTime(dt, opts) {
			if (!dt) {
				return "";
			}
			if (!opts) { opts = {}; }
			if (!dt.format) { dt = new Date(dt); }
			var dtStr = dt.format(opts.dateFormat || null);

			if (opts.skipTime || (opts.skipMidnight && dt.getHours()+dt.getMinutes()+dt.getSeconds() === 0)) {
				return dtStr;
			}

			return dtStr + "  " + formatTime(dt, "h:mm a");
		}
		function formatTime(date, format) {
			if (!date) {
				return "";
			}
			var s = format;
			s = s.replace(/hh/g, numToStr(date.getHours(),2));
			var amPmHr = date.getHours();
			if (amPmHr >= 13) amPmHr -= 12;
			if (amPmHr === 0) amPmHr = 12;
			s = s.replace(/h/g, amPmHr);
			s = s.replace(/mm/g, numToStr(date.getMinutes(), 2));
			s = s.replace(/ss/g, numToStr(date.getSeconds(), 2));
			s = s.replace(/ll/g, numToStr(date.getMilliseconds(), 3));
			s = s.replace(/m/g, date.getMinutes());
			s = s.replace(/a/g, date.getHours() < 12 ? "am" : "pm");
			s = s.replace(/A/g, date.getHours() < 12 ? "AM" : "PM");
			return s;
		}

	    function datepicker(field, spec) {
			// make sure JQuery is available
			if(typeof $ !== "undefined") {
				// see if we've messed with the datepicker already
				if($.datepicker.originalParseDate === undefined) {
					$.datepicker.inputFormats = ["yy-mm-dd", "M d, yy", "M d yy", "dd/mm/yy"];
					$.datepicker.originalParseDate = $.datepicker.parseDate;
					$.datepicker.parseDate = function (format, value, settings) {
					    var date;
					    var i;
					    var stop;
	
					    for(i = 0, stop = $.datepicker.inputFormats ? $.datepicker.inputFormats.length : 0; i < stop; i++){
					        if(!date) {
					            try {
					                date = $.datepicker.originalParseDate($.datepicker.inputFormats[i], value, settings);
					            } catch (Error) {
					            }
					        }
					    }
					    return date;
					};
					// enable all input characters - use of M or D in input format effectively does this anyways
					$.datepicker.setDefaults({constrainInput: false});
				}
			}
			field.datepicker(spec);
		}
	    
	    function getCurrentTimeZoneDisplay(locale) {
				if (!locale) {
					locale = "en-us";
				}
				if (locale === "fr") {
					// Override generic French locale to Canadian French because European French displays timezones as
					// "UTC-x" instead of an acronym (ex. "HNE") in Canadian French.
					// https://french.stackexchange.com/questions/32194/how-to-translate-time-zones-to-canadian-french
					locale = "fr-CA";
				}
	    		try {
	    			// For modern browsers, this will return either shorthanded tz such as EDT, PST etc
	    			var tzStr = new Date().toLocaleDateString(locale, {timeZoneName: "short"}).split(" ")[1];
	    			if (!tzStr) {
	    				throw new Error(OTranslate.t("Undefined timezone string from toLocaleDateString. Could be unsupported", "OUtils.js.undefined_timezone_string_warning"));
	    			}
	    			return tzStr;
	    		} catch (e) {
	    			// Fallback for IE, this will return full tz string such as (Pacific Daylight Time)
	    			var timeStr = new Date().toTimeString().match(/\(([^)]+)\)/);
	    			if (timeStr) {
	    				return timeStr[1]; // Already in brackets
	    			}
	    			return "";
	    		}	
	    }

		return {
			format : format,
			formatDateTimeWithTimeZone: formatDateTimeWithTimeZone,
			formatDateTime: formatDateTime,
			formatTime: formatTime,
			datepicker: datepicker,
			getCurrentTimeZoneDisplay: getCurrentTimeZoneDisplay
		};
	})();
	
	// Avoid `console` errors in browsers that lack a console. Hopefully will permafix weird
	// IE errors related to console use.
	// Taken from: https://github.com/h5bp/html5-boilerplate/blob/master/js/plugins.js
	(function() {
	    var method;
	    var noop = function () {};
	    var methods = [
	        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
	        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
	        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
	        'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
	    ];
	    var length = methods.length;
	    var console = (window.console = window.console || {});

	    while (length--) {
	        method = methods[length];

	        // Only stub undefined methods.
	        if (!console[method]) {
	            console[method] = noop;
	        }
	    }
	}());

	if(!window.location.origin) { window.location.origin = window.location.protocol+"//"+window.location.host; }
	if (!Array.prototype.indexOf) { 
	    Array.prototype.indexOf = function(obj, start) {
	         for (var i = (start || 0), j = this.length; i < j; i++) {
	             if (this[i] === obj) { return i; }
	         }
	         return -1;
	    };
	}
	StrUtil = (function() {
		// IE7 and IE8 do not support .trim(), so we'll define it ourselves
		if (typeof String.prototype.trim !== 'function') {	
			String.prototype.trim = function() {
				return this.replace(/^\s+|\s+$/g, '');
			};
		}
		return {
			concat: function(strs, delim) {
				var str = "";
				for (var i = 0; i < strs.length; i++) {
					var s = strs[i];
					if (s && s.length > 0) {
						if (str.length > 0) {
							str += delim;
						}
						str += s;
					}
				}
				return str;
			},
			prefix : function(prefix, str) {
				if (!str || str.length === 0) return "";
				return prefix + str;
			},
			suffix : function(str, suffix) {
				if (!str || str.length === 0) return "";
				return str + suffix;
			},
			cap : function(str) {
				if (!str || str.length === 0) return str;
				return str.charAt(0).toUpperCase() + str.slice(1);
			},
			minDigits: function(num, min) {
				var str = num.toString();
				while(str.length < min)
					str = "0" + str;
				return str;
			},
			escapeHtml: function(str) {//for xss
				var div = document.createElement('div');
				div.appendChild(document.createTextNode(str));
				return div.innerHTML;
			},
			toHtml: function(str) {
				return str.replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/\n/g, "<br>");
			},
			htmlSafe: function(html) {
				return $("<div/>").text(html).html()
					.replace(/&lt;(\/|)(b||i|u|br|p|strong|ul|ol|li|em|span|span class=\"[a-zA-Z ]*\"( contenteditable=\"false\")?|font|font color=\"#[0-9a-fA-F]*\")&gt;/gi , "<$1$2>")
					.replace(/&amp;/g, "&")
					.replace(/&lt;br\s*\/&gt;/gi, "<br>");
			},
			getTextWithLineBreaks: function(tag) {
				return $("<div/>").html(tag.html().replace(/<br>|<p>/gi, "\n").replace(/<\/br>|<\/p>/gi, "")).text();
			},
			empty : function(str) { return !str || str.trim().length === 0; },
			emptyIfNull : function(obj) { return obj ? obj : ""; },
			sanitizeCsvValue : function (obj) {
				if (obj === null || obj === undefined) {
					return "";
				}
				obj = obj.toString();
				var startsWithSpecialMetaCharacter = obj.startsWith("=") ||
													obj.startsWith("@") ||
													obj.startsWith("+") ||
													obj.startsWith("-");
				obj = startsWithSpecialMetaCharacter ? "'" + obj : obj;
				obj = obj.replace(/"/g, '""');
				return '"' + obj + '"';
			},
			nullIfEmpty : function(obj) { return obj === "" ? null : obj; }
		};
	})();
	ValidationUtil = (function() {
		function validEmail(str) {
			var emails = null;
			if (!str) { return false; }
			var i;
			if (str.trim() === "" || str.indexOf(":") !== -1) {
				return false;
			}
			if (str.indexOf(",") !== -1 || str.indexOf(";") !== -1 || str.indexOf(" ") !== -1) {
				emails = str.split(/[\s,;]/);
				for (i = 0; i < emails.length; ++i) {
					if (emails[i] === "") { continue; }
					if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(emails[i])) {
						return false;
					}
				}
				return true;
			}
			//regex from https://emailregex.com/
			return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(str);
		}
		function validUserName(str) {
			if (!str || (str.length < 5)) { return false; }
			return /^[a-zA-Z0-9]+$/.test(str);
		}
		function validPassword(str) {
			if (str.length < 12) {
				return OTranslate.t("The password must be at least 12 characters long.", "OUtils.js.password_length_requirement");
			}
			var hasUpperCase = /[A-Z]/.test(str);
			var hasLowerCase = /[a-z]/.test(str);
			var hasNumbers = /\d/.test(str);
			var hasNonalphas = /\W/.test(str);
			if (hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas < 3) {
				return OTranslate.t("The password must contain at least 3 of the following: " +
					"uppercase characters, " +
					"lowercase characters, " +
					"numeric digits, " +
					"special characters.", "OUtils.js.password_complexity_requirement");
			}

			return null;
		}
		function validPhone(p) {
			p = AddressUtil.stripPhone(p);
			return p.match(/^([0-9]){10,11}(x[0-9]*)?$/) ? true : false;
		}
		function validPostal(p) {
			return p.match(/^[a-zA-Z][0-9][a-zA-Z][ ]?[0-9][a-zA-Z][0-9]$/);
		}
		function validBillingNum(b){
			return b.match(/^[0-9-]{5,12}$/) && !b.match(/^0+$/);
		}
		function validProfessionalId(p){
			return p.match(/^[a-zA-Z0-9-]{4,12}$/) && !p.match(/^0+$/);
		}
		function validHealthNumber(p) {
			if (typeof p == "string") {
				var stripped = p.replace(/[\s-]/g, "");
				var isAlphaNumericOnly = /^[a-zA-Z0-9]*$/.test(stripped);
				var hasADigit = /\d/.test(stripped);
				var isLongEnough = stripped.length >= 5;
				if (isAlphaNumericOnly && hasADigit && isLongEnough) {
					return stripped;
				}
			}
			return null;
		}
		function stripHealthNumberInput(healthNumber) {
			if (typeof healthNumber == "string") {
				// Stripping out any non-alphanumeric characters
				const alphanumericHealthNumber = healthNumber.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
				if (checkIfOntarioHealthNumberWithVC(alphanumericHealthNumber)) {
					// If the health number is an Ontario format of 10 digits + 2 letters, then we strip off the letters at the end
					return alphanumericHealthNumber.replace(/[a-zA-Z]+$/, "");
				}
				return alphanumericHealthNumber;
			}
			return null;
		}
		function checkIfOntarioHealthNumberWithVC(healthNumber) {
			return /^\d{10}[a-zA-Z]{2}$/.test(healthNumber);
		}
		function parseCanadianProvince(provinceStr) {
			// i18n: do these cases need to be translated?
			if (!provinceStr) {
				return null;
			}
			// Remove any extra spaces between words and period marks to reduce the number of cases
			var cleanedProv = provinceStr.trim().toLowerCase().replace(/ +/g, " ").replace(/[.]/g, "");
			
			// https://en.wikipedia.org/wiki/Canadian_postal_abbreviations_for_provinces_and_territories
			switch (cleanedProv) {
			case "ab":
			case "alta":
			case "alb":
			case "alberta":
				return "AB";
			case "bc":
			case "cb":
			case "c-b":
			case OTranslate.t("british columbia", "OUtils.js.province_british_columbia"):
				return "BC";
			case "mb":
			case "man":
			case "manitoba":
				return "MB";
			case "nb":
			case "n-b":
			case OTranslate.t("new brunswick", "OUtils.js.province_new_brunswick"):
				return "NB";
			case "nl":
			case "tnl":
			case "t-n-l":
			case "newfoundland":
				return "NL";
			case "ns":
			case "ne":
			case "n-e":
			case OTranslate.t("nova scotia", "OUtils.js.province_nova_scotia"):
				return "NS";
			case "on":
			case "ont":
			case "ontario":
				return "ON";
			case "pei":
			case "ipe":
			case "i-p-e":
			case OTranslate.t("prince edward island", "OUtils.js.province_prince_edward_island"):
			case "pe":
				return "PE";
			case "qc":
			case "qu":
			case "que":
			case "pq":
			case "quebec":
				return "QC";
			case "sk":
			case "sask":
			case "saskatchewan":
				return "SK";
			
			case "yt":
			case "yuk":
			case "yn":
			case "yk":
			case "yukon":
				return "YT";
			case "nt":
			case "nwt":
			case "tno":
			case "tn-o":
			case OTranslate.t("northwest territories", "OUtils.js.province_northwest_territories"):
				return "NT";
			case "nu":
			case "nvt":
			case "nunavut":
				return "NU";
			default:
				return null;
			}
		}
		function validCanadianProvince(provinceStr) {
			return parseCanadianProvince(provinceStr) !== null;
		}
		return {
			validEmail: validEmail,
			validPhone: validPhone,
			validPostal: validPostal,
			validUserName: validUserName,
			validPassword: validPassword,
			validBillingNum: validBillingNum,
			validProfessionalId: validProfessionalId,
			validHealthNumber: validHealthNumber,
			stripHealthNumberInput: stripHealthNumberInput,
			parseCanadianProvince: parseCanadianProvince,
			validCanadianProvince: validCanadianProvince,
		};
	})();
	AddressUtil = (function() {
		function simplifyUrl(url) {//strip protocol and trailing slash
			return url.replace(/.*?:\/\//g, "").replace(/\/$/,"");
		}
		function formatPhone(p, ext) {
			if (ext) {
				p += " x" + ext;
			}
			p = stripPhone(p);
			if (ValidationUtil.validPhone(p)) {
				if (p.match(/[0-9]{11}/)) {
					p = p[0]+"-"+p.substr(1,3)+"-"+p.substr(4,3)+"-"+p.substr(7);
				}
				else {
					p = p.substr(0,3)+"-"+p.substr(3,3)+"-"+p.substr(6);			
				}
			}
			//Added more options to sync up with what was in Address.cleanPhoneString in the server side code -HM
			p = p.replace("extension", "x");
			p = p.replace("ext.", "x");
			p = p.replace("ext", "x");
			p = p.replace("x", " x");
			return p;
		}
		function stripPhone(p) {
			//added space to the list of matching regex to format the phone number better
			return (p || "").replace(/[+()\-.\s]/g, "");
		}
		function displayAddressInPanel(panel, addr) {
			function setVal(field, value, url) {
				if (field.is("input")) field.val(value);
				else field.text(value);
				if (field.is("a") && url && url.length > 0) {
					if (url.indexOf(":") === -1) url = "http://"+url;
					field.attr("href", url);
				}
			}
			setVal(panel.find(".line1"), addr.line1);
			setVal(panel.find(".line2Label"),addr.line2Label);
			setVal(panel.find(".line2"),addr.line2);
			var streetAddr = addr.line1 + (addr.line2 ? " " + addr.line2 : "");
			setVal(panel.find(".line1line2"), streetAddr);
			setVal(panel.find(".city"),addr.city);
			setVal(panel.find(".province"),addr.province);
			var cityProv = StrUtil.concat([addr.city, addr.province], ", ");
			setVal(panel.find(".cityProv"),cityProv);
			setVal(panel.find(".cityProvPostal"),[cityProv, addr.postalCode].join(" "));
			setVal(panel.find(".postalCode"),addr.postalCode);
			var phone = addr.phone || addr.businessPhone || addr.mobilePhone || addr.homePhone || "";
			setVal(panel.find(".phone"),phone,phone?"tel:"+phone.replace(/- /g, ""):"");
			panel.find(".phoneIcon").toggle(phone.length > 0);
			setVal(panel.find(".fax"),addr.fax || "");
			panel.find(".faxIcon").toggle((addr.fax && addr.fax.length > 0) ? true:false);
			setVal(panel.find(".homePhone"),StrUtil.suffix(formatPhone(addr.homePhone), OTranslate.t(" (H)", "OUtils.js.home_phone_label")));
			setVal(panel.find(".businessPhone"),StrUtil.suffix(formatPhone(addr.businessPhone, addr.businessPhoneExt), OTranslate.t(" (B)", "OUtils.js.business_phone_label")));
			setVal(panel.find(".mobilePhone"),StrUtil.suffix(formatPhone(addr.mobilePhone), OTranslate.t(" (M)", "OUtils.js.mobile_phone_label")));
			setVal(panel.find(".email"),addr.email,addr.email?"mailto:"+addr.email:"");
			panel.find(".emailIcon").toggle((addr.email && addr.email.length > 0) ? true:false);
			// patientFacingEmail only shows up in ReferralTargetDialog.html once and nowhere else - therefore use ID instead of class
			setVal(panel.find("#patientFacingEmail"), addr.patientFacingEmail, addr.patientFacingEmail?"mailto:"+addr.patientFacingEmail:"");
			setVal(panel.find(".website"), addr.website ? simplifyUrl(addr.website) : "", addr.website);
			panel.find(".websiteIcon").toggle((addr.website && addr.website.length > 0)? true:false);
		}
		function phoneToString(addr, spec) {
			if (!spec) spec = {};
			var delim = spec.delim ? spec.delim : "  ";
			var phones = [];
			if (addr.phone) phones.push(StrUtil.prefix(OTranslate.t("Phone: ", "OUtils.js.phone_label"), addr.phone));
			if (addr.businessPhone) phones.push(StrUtil.suffix(addr.businessPhone, OTranslate.t(" (B)", "OUtils.js.business_phone_label")));
			if (addr.homePhone) phones.push(StrUtil.suffix(addr.homePhone, OTranslate.t(" (H)", "OUtils.js.home_phone_label")));
			if (addr.mobilePhone) phones.push(StrUtil.suffix(addr.mobilePhone, OTranslate.t(" (M)", "OUtils.js.mobile_phone_label")));
			if (addr.fax) phones.push(StrUtil.prefix(OTranslate.t("Fax: ", "OUtils.js.fax_label"), addr.fax));
			if (spec.html) {
				for (var i = 0; i < phones.length; i++) {
					phones[i] = "<span class='nowrap'>" + StrUtil.escapeHtml(phones[i]) + "</span>";
				}
			}
			return phones.join(delim).trim();
		}
		function addressToString(addr, spec) {
			if (!addr) return "";
			var addrTokens = [];
			if (addr.line1) addrTokens.push(StrUtil.concat([addr.line1, addr.line2], " "));
			if (addr.city) addrTokens.push(addr.city);
			if (addr.province) addrTokens.push(addr.province);
			if (addr.postalCode) addrTokens.push(addr.postalCode);
			var desc = addrTokens.join(", ").trim();
			if (spec) {
				if (spec.phone || spec.full) {
					desc += "\n" + phoneToString(addr, spec);
				}
				if (spec.email || spec.full) {
					desc += "\n" + addr.email;
				}
				desc = desc.trim();
				if (spec.oneLine)
					desc = desc.replace(/\n/g, "  ");
			}
			return desc;
		}
		return {
			simplifyUrl: simplifyUrl,
			displayAddressInPanel: displayAddressInPanel,
			addressToString: addressToString,
			phoneToString: phoneToString,
			stripPhone: stripPhone,
			formatPhone: formatPhone
		};
	})();
	
	FileUtil = (function() {
		// files is an array of files and maxFileSize is int representing size in MiB
		function areFilesOversized(files, maxFileSize) {
			for (var i = 0; i < files.length; i++) {
				var file = files[i];
				if (file.size > (maxFileSize * 1024 * 1024)) {
					OTranslate.i18nInitTest({ namespaces: ["webq"] }, function() {
						var fileTooBigError = OTranslate.t("The selected file \"{{fileName}}\" exceeds the limit of {{maxFileSize}}MB per file. Please consider splitting or compressing the file and try again.",
							"webq.fileTooBigError", {fileName: file.name, maxFileSize: maxFileSize});
						OUtils.showError(fileTooBigError);
					});
					return true;
				} else if (file.size === 0) {
					OTranslate.i18nInitTest({ namespaces: ["webq"] }, function() {
						var fileNoLongerAvailable = OTranslate.t("The selected file \"{{fileName}}\" is no longer available. It is zero bytes large. Please remove it and try again if necessary.",
							"webq.fileNoLongerAvailableError", {fileName: file.name});
						OUtils.showError(fileNoLongerAvailable);
					});
					return true;
				}
			}
			return false;
		}
		function sendFileRequest(url, args, callback) {
			var token = OUtils.getCookie("XSRF-TOKEN");
			var xhr = new XMLHttpRequest();
			xhr.open('GET', url, true);
			xhr.setRequestHeader('X-XSRF-TOKEN', token);
			xhr.send();
			xhr.onreadystatechange = function () {
				if (xhr.status === 200) {
					if (xhr.readyState === 4) {
						downloadResponseFile(url, xhr, args.contentType, args.filename);
					}
				} else {
					OTranslate.i18nInitTest({ namespaces: ["shared"] }, function() {
						OUtils.showError(args.errorMsg ? args.errorMsg : OTranslate.t("An error occurred generating the file.", "OUtils.js.file_generation_error_message"));
					});
				}
				if (callback) {
					callback();
				}
			}
		}
		function getFilenameFromResponse(xhr){
			var dispositionHeader = xhr.getResponseHeader('Content-Disposition');
			var splitHeader = dispositionHeader ? dispositionHeader.split("filename=") : null;
			return (splitHeader && splitHeader.length === 2 ? decodeURIComponent(splitHeader[1]) : null);
		}
		function getFilenameFromUrl(url) {
			// if no filename returned, report can be empty file with the no results message
			// in this case, fallback to using the name of the endpoint as the filename
			var urlPath = url.split("?")[0];
			var splitUrl = urlPath ? urlPath.split("/") : null;
			return splitUrl ? splitUrl[splitUrl.length - 1] : "Report";
		}
		function downloadResponseFile(url, xhr, contentType, filename) {
			var rawData = xhr.response || xhr.responseText; // bug with ajax, response isn't always defined
			var blob = new Blob([rawData], {type: contentType});
			var objectUrl = URL.createObjectURL(blob);
			if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE
				window.navigator.msSaveOrOpenBlob(blob);
			} else {
				if (!filename) {
					filename = getFilenameFromResponse(xhr);
				}
				if (!filename) {
					filename = getFilenameFromUrl(url);
				}
				var a = document.createElement('a')
				a.href = objectUrl;
				a.download = filename;
				document.body.appendChild(a); // some browsers require link added to DOM before click
				a.click();
				document.body.removeChild(a);
				
				setTimeout(function () {
					URL.revokeObjectURL(objectUrl);
				}, 100)
			}
		}
		function replaceBadFileNameChars(fileName) {
			if (!fileName) {
				return "";
			}
			// Replace all illegal ASCII characters, and then all ASCII control characters 0-31 to have valid filename on Windows
			return fileName.replace(/[\\\/:*?"<>|]/g, "_").replace(/[\u0000-\u001F]/g, "_").trim();
		}
		function setPatientEngagementAttachmentKeyCookie(key, path, fileName) {
			var expireTime = new Date();
			expireTime.setTime(expireTime.getTime()+1000*60*60); // expire in 60 minutes
			var cookieDetails = ";expires=" + expireTime.toGMTString() + ";path="+path;
			document.cookie = "encryptionKey=" + key + cookieDetails;
			document.cookie = "fileName=" + encodeURIComponent(fileName.replace(/[()]/gi, "")) + cookieDetails;
		}
		
		/**
		 * @param {object} spec
		 * @param {string} spec.attachmentRef
		 * @param {string} spec.decryptedFileName
		 * @param {string} spec.decryptedAttachmentKey
		 * @param {string?} spec.accessToken Needed when accessing from the patient
		 * @param {boolean?} spec.previewOnly Opens the file without triggering a download
		 * @param {boolean?} spec.newTab Opens the file in a new tab
		 */
		function openPatientEngagementAttachment(spec) {
			var path = "/patients/msg/attachment/" + spec.attachmentRef;
			var args = ""
			if (spec.previewOnly) {
				args += "?triggerDownload=false"
			} else {
				args += "?triggerDownload=true";
			}
			if (spec.accessToken) {
				args += "&accessToken=" + spec.accessToken;
			}
			
			setPatientEngagementAttachmentKeyCookie(spec.decryptedAttachmentKey, path, spec.decryptedFileName);
			if (spec.newTab) {
				window.open(path+args,  '_blank');
			} else {
				window.location = path+args;
			}
		}
		
		return {
			areFilesOversized: areFilesOversized,
			sendFileRequest: sendFileRequest,
			replaceBadFileNameChars: replaceBadFileNameChars,
			downloadResponseFile: downloadResponseFile,
			setPatientEngagementAttachmentKeyCookie: setPatientEngagementAttachmentKeyCookie,
			openPatientEngagementAttachment: openPatientEngagementAttachment,
		};
	})();
	
	//Uses the Revealing Module pattern: http://www.klauskomenda.com/code/javascript-programming-patterns
	OUtils = (function() {
		if(typeof $ !== "undefined" && typeof $.fn !== "undefined") {
			$.fn.center = function() {
				this.css("position", "absolute");
			    this.css("top", Math.max(0, (($(window).height() - $(this).outerHeight()) / 2) + 
			                                                $(window).scrollTop()) + "px");
			    this.css("left", Math.max(0, (($(window).width() - $(this).outerWidth()) / 2) + 
			                                                $(window).scrollLeft()) + "px");
				return this;
			};
		}
		function openWindow(blob) {
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			    window.navigator.msSaveOrOpenBlob(blob);
			}
			else {
			    var url = URL.createObjectURL(blob);
			    window.open(url);
			}
		}
		var templateCache = {};
		function cloneTemplate(id, templateParentPanel) {
			if (id.charAt(0) != "#") {
				id="#"+id;
			}
			if (!templateParentPanel) {
				templateParentPanel = $("body");
			}
			var key = templateParentPanel[0].id + templateParentPanel[0].className + "-" + id;
			var template = templateCache[key];
			if (!template) {
				template = templateParentPanel.find(id).first().clone(true).removeClass("template").attr("id",null);
				templateCache[id] = template;
			}
			var result = template.clone(true);
			if (result.length === 0) {
				var e = new Error(OTranslate.t("missing template: {{id}}", "OUtils.js.missing_template_warning", {id: id}));
				// JF - 2020-07-31: Making these template errors silent to prevent RPC callbacks that are initiated
				// before a page navigation and executed after the navigation happens from harassing the user
				if (!isLocalhost()) {
					e.silent = true;
				}
				throw e;
			}
			return result;
		}
		function getURLVars() {
		    var vars = {};
		    window.location.href.replace(/[?&]+([^=&]+)=([^&#]*)/gi, function(m,key,value) {
		        vars[key] = decodeURIComponent(value);
		    });
		    return vars;
		}
		function getAnchorTag() {
			var anchorTag = window.location.hash;
			if (anchorTag.length <= 1) {
				return null;
			} else {
				return anchorTag.substring(1);//drop the #
			}
		}
		function showNote(note, args) {
			if (!args) { args = {}; }
			var okText = args.okText || OTranslate.t("OK", "default.okButton");
			if (!args.title) {
				args.title = OTranslate.t("Message", "default.OUtils.noteDialogTitle");
			}
			var dialog = $("<div id='dialogNote' tabindex='0'></div>");
			var dialogClass;
			if (args && args.dialogClass) {
				dialogClass = args.dialogClass;
			}
			var modal = true;
			if (args && args.modal === false) {
				modal = false;
			}

			// Thing that breaks everything
			if (args.useHtml) {
				// Warning, may cause cross site scripting vulnerabilities
				dialog.html(note);
			} else {
				dialog.text(note);
			}
			var buttons = {};
			buttons[okText] = function() { 
				$(this).dialog("close").remove();
				if (args && args.callback) {
					args.callback();
				}
			};
			hideWait();
			dialog.dialog({
				title: args.title,
				dialogClass: dialogClass,
				minWidth: args.minWidth || 400,
				buttons: buttons,
				modal: modal,
				position: args.position,
				zIndex: 2000 // Default is 1000
			});
			dialog.dialog("moveToTop");
			if (args.preselect) {
				selectText(dialog);
				dialog.focus();
			}	
		}
		function selectText(tag) {
			try {
			    var doc = document;
			    var text = tag[0];
			    var range;
			    if (doc.body.createTextRange) { // ms
			        range = doc.body.createTextRange();
			        range.moveToElementText(text);
			        range.select();
			    } else if (window.getSelection) {
			        var selection = window.getSelection();
			        range = doc.createRange();
			        range.selectNodeContents(text);
			        selection.removeAllRanges();
			        selection.addRange(range);
			    }
			}
			catch (e) {
				console.error(e);
			}
		}
		function showError(msg, callback, args) {
			var title = OTranslate.t("Error", "default.errorDialogTitle");
			var okText = OTranslate.t("OK", "default.okButton");
			hideWait();
			if ($("#dialogError").length > 0) {
				console.log("Suppressed additional error message: " + msg);
				return;
			}
			var buttons = {};
			buttons[okText] = function() {
				$( this ).dialog("close");
			};
			var dialogArgs = {
				title: title, 
				modal : true,
				resizable: false,
				closeOnEscape: true,
				buttons: buttons,
			    zIndex: 2000, // Default is 1000
			    close: function() {
			    	$(this).remove();
					if (callback) {
						callback();
					}
			    }
			};
			for (var i in args) {
				dialogArgs[i] = args[i];
			}
			$("<div id='dialogError'/>").html(StrUtil.htmlSafe(msg)).dialog(dialogArgs).dialog("moveToTop");
		}
		function showInput(spec) {
			var msg = spec.msg;
			var dialogTitle = spec.dialogTitle;
			var okText = spec.okText;
			var okFunction = spec.okFunction;
			var emptyInputErrorMsg = spec.emptyInputErrorMsg;
			hideWait();
			if (!okText || okText.length === 0) {
				okText = "OK";
			}
			var okBtn = {
				text: okText, 
				click: function() { 
					defaultAction($(this));
				}
			};
			var cancelBtn = {
				text: OTranslate.t("Cancel", "OUtils.js.cancel_button"), 
				click: function() { 
					$(this).dialog("close");
					if (spec.cancelFunction) {
						spec.cancelFunction();
					}
				}
			};
			var buttons = null;
			if (!spec.hideCancelBtn) {
				buttons = [cancelBtn, okBtn];
			} else {
				buttons = [okBtn];
			}
			var inputField = $("<input>");
			inputField.attr("autofocus","autofocus").addClass("k-dialog-showinput");
			if (spec.inputFieldType === "date") {
				DateUtil.datepicker(inputField, {
					dateFormat: "yy-mm-dd"
				});
			} else if (spec.inputFieldType === "password") {
				inputField.attr("type", "password")
			} else {
				inputField.attr("type", "text")
			}
			var dialogClass;
			if (spec.dialogClass) {
				dialogClass = spec.dialogClass;
			}
			inputField.val(spec.inputDefault);
			var dialog = $("<div/>").html(msg);
			dialog.append(inputField);
			var dialogSpec = spec.dialogSpec || {};
			$.extend(dialogSpec, {
				title: dialogTitle,
				dialogClass: dialogClass,
				modal: true,
				buttons: buttons,
			    zIndex: 2000 // Default is 1000
			});
			dialog.dialog(dialogSpec);
			dialog.dialog("moveToTop");
			function defaultAction(dialog) {
				var inputStr = inputField.val();
				var emptyInputCheck = (!inputStr || inputStr.length === 0);
				
				if (emptyInputErrorMsg && emptyInputCheck) {
					OUtils.showError(emptyInputErrorMsg);
					return;
				}
				dialog.dialog("close");
				okFunction(inputStr);
			}
			inputField.keypress(function(event) {
				if (event.keyCode == 13) {
					defaultAction(dialog);
				}
			});
			window.setTimeout(function() {
				inputField.select().focus();
			}, 0);
			return dialog;
		}
		function dialog(dialog, spec) {
			$.extend(spec, {
			    close: function(event, ui) {
			    	dialog.dialog("destroy").remove();//remove completely from DOM
			    }
			});
			return dialog.dialog(spec);
		}
		
		/**
		 * Returns a confirmation dialog
		 * @param msg
		 * @param dialogTitle
		 * @param confirmText
		 * @param confirmFn
		 * @param dialogSpec Additional specifications to the dialog, including cancel text, cancel function, and close functions
		 * @returns {*|jQuery|HTMLElement} A confirmation dialog
		 */
		function confirm(msg, dialogTitle, confirmText, confirmFn, dialogSpec) {
			hideWait();
			var buttons = {};
			var dialog = $("<div>"+msg+"</div>");
			if (!dialogSpec) {
				dialogSpec = {};
			}
			var cancelText = dialogSpec.cancelText || OTranslate.t("Cancel", "OUtils.js.cancel_button");
			function close() {
				dialog.dialog("destroy").remove();
				if (dialogSpec.duringCloseFn) {
					dialogSpec.duringCloseFn();
				}
			}
			
			buttons[confirmText] = function() {
				close();
				confirmFn();
			};
			buttons[cancelText] = function() {
				close();
				if (dialogSpec.cancelFn) { dialogSpec.cancelFn(); }
			};
			$.extend(dialogSpec, {
				modal : true,
				title: dialogTitle, 
				zIndex : 2000, // Default is 1000
				buttons: buttons,
			    close: function(event, ui) {
					// exclusively called if "x" button is pressed
					if (dialogSpec.closeFn) {
						dialogSpec.closeFn();
					}
					close();
			    }
			});
			dialog.dialog(dialogSpec);
	        dialog.dialog("moveToTop");
			var buttonSet = dialog.parent().find(".ui-dialog-buttonset").css("width", "100%").attr("data-testid", "buttonSet");
			buttonSet.find("button:first-child").attr("data-testid", "confirmDeleteConfirmButton")
			buttonSet.find("button:last-child").attr("data-testid", "confirmDeleteCancelButton")
			buttonSet.find("button").css("width", "100%");
			return dialog;
		}
		/**
		 * Display multiple button dialog
		 * @param msgHtml
		 * @param buttons array of objects with {text, click, data} fields
		 * @param title
		 * @param dialogSpec Additional specifications to the dialog, including cancel text, cancel function, and close functions
		 */
		function multiAnswerAsk(msgHtml, buttons, title, dialogSpec) {
			var d;
			function closeFirst(buttonFn, data) {
				return function() {
					d.dialog("close");
					buttonFn(data);
				};
			}
			hideWait();
			for (var i in buttons) {
				buttons[i] = {
					text: buttons[i].text,
					click: closeFirst(buttons[i].click, buttons[i].data)
				};
			}
			if (!title) {
				title = "";
			}
			if (!dialogSpec) {
				dialogSpec = {};
			}
			if (dialogSpec.hideCloseBox) {
				dialogSpec.dialogClass = "ui-dialog-no-close";
			}
			$.extend(dialogSpec, {
				title: title, 
				buttons: buttons,
				modal: true
			});
			d = dialog($("<div>"+msgHtml+"</div>"), dialogSpec);
			d.parent()[0].scrollIntoView(true);
			var buttonSet = d.parent().find(".ui-dialog-buttonset").css("width", "100%");
			buttonSet.find("button").css("width", "100%");
		}
		function promptCancelChanges() {
			multiAnswerAsk(OTranslate.t("Are you sure you want to discard your changes?", "OUtils.js.discard_changes_confirmation"), [
				{
					text: OTranslate.t("Yes - Discard Changes", "OUtils.js.yes_discard_changes_button"),
					click: function() {
						window.location.reload();
					}
				}, {
					text: OTranslate.t("Cancel", "OUtils.js.cancel_button"),
					click: function() {
						$(this).dialog("close");
					}
				}
			]);
		}
		var popupsInitialized = false;
		function initializePopups() {
			function closeTopPopup(e) {
				var target = $(e.target);
				var popupsToClose = $(".oBackgroundAutoClose");
				var i, popup;
				for (i = popupsToClose.length-1; i >= 0; i--) {
					popup = $(popupsToClose[i]);
					var ignore = "oPopup, .ui-widget-overlay, .ui-datepicker, .ui-menu, .ui-dialog-titlebar";
					if (target.is(ignore) || target.parents(ignore).length > 0) { 
						continue;
					}
					if (!popup.hasClass("oMenu") && target.parents(".ui-dialog").length > 0 && target.parents(".ui-dialog").find(popup).length === 0) {
						continue;//ignore unless dialog is an ancestor of popup - eg sendWebQ dialog shouldn't hide pt info popup 
					}
					if (popup.find(target).length === 0) {
						popup.trigger("oBackgroundAutoClose");
						break;
					}
				}
			}
			if (!popupsInitialized) {
				$(document.body).mouseup(closeTopPopup);
				popupsInitialized = true;
			}
		}
		function openTabOrRedirectIfNec(url, options) {
			if (options) {
				if (options.ptRef) {
					sessionStorage.setItem("ptRef", options.ptRef)
				}
				if (options.referralRedirect) {
					localStorage.setItem("referralRedirect", JSON.stringify(options.referralRedirect));
				}
			}
			var popup = window.open(url);
			try {
				popup.focus();
			}
			catch(e) {
				window.location = url;
			}
		}
		function showPopup(panel, options) {
			initializePopups();
			var popup = $("<div></div>").append(panel).dialog({
				title: options.title || "",
				appendTo: options.parent,
				modal : options.modal || false,
				position: options.position || [options.left || "center", options.top !== undefined ? options.top : 40], 
				resizable : false,
				width : options.width || null,
				height: options.height || "auto",
				minHeight: options.minHeight,
				maxHeight: options.maxHeight,
				closeOnEscape: true,
				show: options.show,
				buttons: options.buttons,
				beforeClose: options.beforeClose,
				dialogClass: options.dialogClass,
				open: options.open,
			    close: function(e, ui) {
			    	popup.dialog("destroy");
			    	if (options.closeCallback) {
			    		options.closeCallback(e);
			    	}
			    }
			});
			panel.show();
			var container = popup.parent();
			container.addClass(options.modal ? "k-modal" : "k-non-modal");
			if (options.autoClose !== false) {
				container.addClass("oBackgroundAutoClose");
				container.on("oBackgroundAutoClose", function(e) {
					if (options.autoCloseCallback) {
						options.autoCloseCallback(e, popup);
					} else {
						popup.dialog("close");
					}
					e.stopPropagation();
				});
			}
			if (options.closeBox === false) {
				popup.dialog().parent().find(".ui-dialog-titlebar-close").remove();
			}
			if (options.titleBar === false) {
				popup.dialog().parent().find(".ui-dialog-titlebar").remove();
			}
			return popup;
		}
		function menu() {
			var menuElem = $("<li/>").addClass("oPopup oBackgroundAutoClose oMenu dropdown-menu");
			menuElem.on("oBackgroundAutoClose", function(e) { menuElem.remove(); e.stopPropagation(); });
			return menuElem.extend({
				addItem: function(html, action) {
					var item = $("<li/>").append($("<a/>").htmlSafe(html));
					var selectAction = function() {
						menuElem.remove();
						if (action) action();
					};
					item.click(selectAction);
					$(this).append(item);
					return item;
				},
				addDisabledItem: function(html) {
					var span = $("<span/>").addClass("k-subtle").htmlSafe(html);
					var text = $("<a/>").append(span);
					var item = $("<li/>").append(text).addClass("disabled");
					$(this).append(item);
					return item;
				},
				addSeparator: function() {
					var item = $("<li/>").attr("role", "separator").addClass("divider");
					$(this).append(item);
				},
				popup: function(parentButton, position, parent) {
					initializePopups();
					if (parent) {
						// to fix the menu in place when scrolling within a modal, pass in a parent element with position:relative
						parent.append($(this));
						menuElem.css("position", "absolute");
					} else {
						$("body").append($(this));
					}
					var menu = $(this).menu({
						select: function (e, ui) {
							ui.item.triggerHandler("click");
						}
					});
					if (!position) {
						position = {
							my: "left top",
							at: "left bottom",
							of: parentButton
						};
					}
					menu.show();
					menu.position(position);
					menu.keydown(function (e) {
						if (e.keyCode == 13 || e.keyCode == 32) {
							menu.select();
							menuElem.remove();
						}
					});
					return menu;
				}
			});
		}
		function isLoginPage(responseText) {
			return responseText.indexOf("#OCEAN-LOGIN-PAGE#") >= 0;
		}
		function activateCaching() {
			$.ajaxSetup ({
			    cache: true
			});
		}
		function loadPage(tag, pageToLoad, callback) { //{success: fn(), fail: fn(), sessionExpired: fn()}
			if (!callback.success) {
				callback = { success: callback };
			}
			lastLoaded = pageToLoad;
			activateCaching();
			$.get(pageToLoad).done(function(responseText, textStatus, xmlHttpReq) {
				if (isLoginPage(responseText)) {
					if (callback.sessionExpired) {
						callback.sessionExpired();
					} else {
						if (callback.fail) {
							callback.fail(responseText, textStatus);
						}
						needsSignInToContinue();
					}
				} else {
					tag.html(responseText);
					callback.success(responseText, textStatus, xmlHttpReq);					
				}
			});
		}
		function loadPageAsPopup(pagePath, options, callback) {
			var origOpenFn = options.open;
			options.open = function(event, ui) {
				if (origOpenFn) {
					origOpenFn(event, ui);
				}
				callback($(event.target));
			}
			var popupPage = $("<div/>");
			loadPage(popupPage, pagePath, function() {
				showPopup(popupPage, options);
			});
		}
		function showOnMouseOver(hoverAreaTag, selector) {
			hoverAreaTag.mouseover(function() {
				$(this).find(selector).show();
			}).mouseleave(function() {
				$(this).find(selector).hide();
			});
			return hoverAreaTag;
		}
	
		function needsSignInToContinue(cb) {
			showPopupLogin(cb || null, {msg: OTranslate.t("Please sign in to Ocean to continue.", "OUtils.js.sign_in_prompt")});
		}
		function showPopupLogin(loginCallback, options) {
			requirejs(["PopupLogin"], function(PopupLogin) {
				PopupLogin.show(loginCallback, options);
			});
		}
		function viewEForm(eFormRef, ptRef, siteNum) {
			var url = "/questionnaires/preview/QuestionnairePreview.html?ref="+eFormRef;
			if (ptRef) {
				url += "&ptRef="+ptRef;
			}
			if (siteNum) {
				url += "&siteNum=" + siteNum;
			}
			OUtils.openTabOrRedirectIfNec(url);
		}

		/**
		 * Prevents non-numeric characters from entering the given field.
		 * Allows 0-9 and '.'. '-' is NOT ALLOWED
		 * The value is not validated to see if it's an actual number
		 * so '...1.1..' would work. Hotkey are not blocked so the user could also paste in
		 * a non-numeric value.
		 * This function is really only necessary on Firefox. Other browsers do it automatically.
		 * @param textField
		 */
		function numericOnly(textField) {
			// uses addEventListener on the actual element
			// bc jQuery's keydown doesn't expose getModifierState
			var allowedChars = "1234567890.";
			var field = textField.addEventListener ? textField : textField[0];
			field.addEventListener("keydown", function (event) {
				if (!event.key || event.key.length > 1) {
					// a non-printable key, so allow
					return;
				}

				var modifiers = [
					OTranslate.t("Alt", "OUtils.js.alt_key"),
					OTranslate.t("AltGraph", "OUtils.js.alt_graph_key"),
					OTranslate.t("Control", "OUtils.js.control_key"),
					OTranslate.t("Fn", "OUtils.js.fn_key"),
					OTranslate.t("Hyper", "OUtils.js.hyper_key"),
					OTranslate.t("Meta", "OUtils.js.meta_key"),
					OTranslate.t("Super", "OUtils.js.super_key")
				];
				
				if (allowedChars.includes(event.key)) {
					// one of the allowed symbols without a modifier. so allow
					return;
				}
				var currentModifier = modifiers.find(function (modifier) { return event.getModifierState(modifier); });
				if (currentModifier) {
					// has a modifier, is probably a shortcut and won't print anything. so allow
					return;
				}
				// a printable char w/o a modifier, so prevent it
				event.preventDefault();
			});
			field.addEventListener("paste", function (event) {
				if (event.clipboardData || window.clipboardData) {
					var paste = (event.clipboardData || window.clipboardData).getData('text');
					var isBadPaste = paste.split("")
						.some(function (character) {
							return !allowedChars.includes(character);
						});
					if (isBadPaste) {
						event.preventDefault();
					}
				}
			});
		}
		
		function upperCaseOnly(textField) {
			textField.keyup(function (event) {
			    this.value = this.value.toUpperCase();
			});
		}
		
		function getCookie(c_name, defaultVal) {
			var i,x,y,ARRcookies=document.cookie.split(";");
			for (i=0;i<ARRcookies.length;i++) {
				x=ARRcookies[i].substr(0,ARRcookies[i].indexOf("="));
				y=ARRcookies[i].substr(ARRcookies[i].indexOf("=")+1);
				x=x.replace(/^\s+|\s+$/g,"");
				if (x==c_name){
					return unescape(y);
				}
			}
			return defaultVal;
		}
		function setCookie(name, value, args) {
			var cookieVal = escape(value);
			var maxAge = "";
			var domain = "";
			if (args) {
				var maxAgeSeconds = args.maxAgeSeconds;
				var domainName = args.domainName;
				
				if (maxAgeSeconds && typeof maxAgeSeconds === "number") {
					maxAge = "; max-age=" + maxAgeSeconds;
				}
				if (domainName && typeof domainName === "string") {
					domain = "; domain=" + domainName;
				}
			}
			document.cookie= name + "=" + cookieVal + maxAge + domain + "; path=/; Secure";
		}
		
		function checkForLocalStorage() {
			if (typeof(localStorage) === "undefined" || !localStorage) {
				throw new Error(OTranslate.t("no localStorage support", "OUtils.js.no_localstorage_support_message"));
			}
		}
		function getLocalStorage(name) {
			checkForLocalStorage();
			return localStorage.getItem(name);
		}

		function setLocalStorage(name, value) {
			checkForLocalStorage();
			try {
				if (value) {
					localStorage.setItem(name, value);
				} else {
					localStorage.removeItem(name);
				}
			} catch(exception) {
				console.log("Ignoring local storage exception.");
				console.log(exception);
			}
		}
		function isLocalhost() {
			return (""+window.location).indexOf("localhost") >= 0;
		}
		function isStaging() {
			return(""+window.location).indexOf("staging") >= 0;
		}
		function isTest() {
			return (""+window.location).indexOf("test") >= 0;
		}
		function isAutotest() {
			return (""+window.location).indexOf("autotest") >= 0;
		}
		function isNightly() {
			return (""+window.location).indexOf("nightly") >= 0;
		}
		function isProd() {
			if(!isLocalhost() && !isStaging() && !isTest() && !isAutotest() && !isNightly()) {
				return true;
			}
			return false;
		}
		function isListingOtnClaimed(siteNum) {
			//this method is only required for staging, prod, or local
			//will return false for all other instances
			if (!siteNum) return false;
			
			if (isProd()) {
				return siteNum === '8038';
			} else if (isStaging() || isLocalhost()) {
				return siteNum === '3253';
			} else {
				return false;
			}
		}
		function getOceanHost() {
			if (isLocalhost()) {
				return "http://localhost:8080";
			} else if (isStaging()) {
				return "https://staging.cognisantmd.com";
			} else if (isAutotest()) {
				return "https://autotest.cognisantmd.com";
			} else if (isTest()) {
				return "https://test.cognisantmd.com";
			} else if (isNightly()) {
				return "https://nightly.cognisantmd.com";
			} else {
				return "https://ocean.cognisantmd.com";
			}
		}
		function getCloudConnectHost(callback) {
			if (!cloudConnectHost) {
				$.get("/public/cloudConnectHost").done(function(responseText) {
					cloudConnectHost = responseText;
					callback(responseText);
				});
			} else {
				callback(cloudConnectHost);
			}
		}
		function getCrlLink() {
			if (isLocalhost()) {
				return "http://localhost:8080/public/crl/crl.html";
			} else if (isStaging()) {
				return "https://www.cognisantmd.com/library-staging/";
			} else {
				return "https://www.cognisantmd.com/library/";
			}
		}
		function isLocalTestVersion() {
			return window.location.host.indexOf("ocean") == -1;
		}
		function handleBadBrowser() {
			if (isBadBrowser()) {
				window.location = "/OldBrowser.html";
			}
		}
		function isBadBrowser() {
			var msie = navigator.userAgent.match(/MSIE ([0-9]+)\./);
			var ieVersion = msie ? msie[1] : null;
			var bad = ieVersion !== null && ieVersion <= 8;
			if (bad) {
				if (getURLVars().overrideBadBrowser) bad = false;
			}
			return bad;
		}
		function onKey(tag, key, callback) {
			return tag.keypress(function(e) {
				if (String.fromCharCode(e.keyCode) == key || e.keyCode == key) callback(e);
			});
		}
		function onSpace(tag, callback) {
			return onKey(tag, " ", callback);
		}
		$.fn.extend({
			findIt: function(tag) {
				var m = this.find(tag);
				if (m.length !== 1) {
					var msg = OTranslate.t("findIt fail: {{tag}}\n{{length}} results", "OUtils.js.findit_failed", {tag: tag, length: m.length});
					if (isLocalhost()) {
						window.alert(msg);
						throw new Error(msg);
					}
					console.log(msg);
				}
				return m;
			},
			htmlSafe: function(h) {
				return this.html(StrUtil.htmlSafe(h));
			},
			autoResize: function(minHeight) {
				var e = $(this).on("input", function() { $(this).resize(minHeight); });
				if (minHeight) {
					e.css("height", minHeight);
				}
				return e;
			},
			resize: function(minHeight) {
				var e = $(this);
				var borderHeight = e.outerHeight() - e.innerHeight();
				return e.css("height", "0").css("height", Math.max(e.prop("scrollHeight")+borderHeight,minHeight||30) + "px");
			}
		});
		function onSpaceAndClick(tag, callback, disableForMs) {
			if (disableForMs) {
				var cb = callback;
				callback = function() {
					tag.prop("disabled", true);
					window.setTimeout(function() { tag.prop("disabled", false); }, disableForMs);
					cb();
				};
			}
			if (tag.is("button")) {
				return tag.click(callback);//space creates a click, so we need to avoid double-handling here
			}
			return onKey(tag, " ", function(e) {
				e.preventDefault();
				callback(e);
			}).click(callback);
		}
		function onEnter(tag, callback) {
			return onKey(tag, 13, callback);
		}
		function onEscape(tag, callback) {
			return tag.keydown(function(e) {//must use keydown here
				if (String.fromCharCode(e.keyCode) == 27 || e.keyCode == 27) {
					callback(e);
					e.stopPropagation();
				}
			});
		}
		function onMac() {
			return navigator.userAgent.indexOf("Mac OS") != -1;
		}
		function addShortcut(tag, keyOptions, callback) {
			if (typeof(keyOptions) === "string") {
				keyOptions = {
					keyChar: keyOptions
				};
			}
			tag.find(":input, select, .textAreaDiv, textarea").add(tag).bind("keydown keyup", function(e) {
				if ((e.metaKey || e.ctrlKey) && (!keyOptions.needsShift || e.shiftKey) && (!keyOptions.needsAlt || e.altKey)) {
					var c = String.fromCharCode(e.keyCode);
					if (e.keyCode === 13) c = "\n";
					if ((keyOptions.keyChar || "").toUpperCase() === c || keyOptions.keyCode === e.keyCode) {
						var handled = callback(e);
						if (handled === true || handled === undefined) {
							e.preventDefault();
							e.stopPropagation();
						}
					}
				}
			});
		}
		function autoDirty(parentTab, dirtyFn, args) {
			var inputFields = parentTab.find(":input, .autoDirty").not(".keepclean");
			if (!args || !args.doNotDirtyOnClick) {
				inputFields.click(dirtyFn);
			}
			inputFields.bind("input", function(e) {
				dirtyFn(e);
			});
			parentTab.find("select.autoDirty").change(dirtyFn);		
		}
		function showWait(msg, delay, fadeIn) {
			var dialog = $("#loading-div-background");
			if (dialog.length === 0) {
				if (!msg) {
					msg = "Please wait...";
				}
				dialog = $(
					"<div id='loading-div-background'>" + 
						"<div id='loading-div'><span class='glyphicon glyphicon-refresh spinning spinning-solo'></span>"+
						"<h4 style='color:gray;font-weight:normal;'>" + msg + "</h4>" + "<input id='showWaitFocus' type='hidden'></div>");
				$(document.body).append(dialog);
			}
			// Fade in nice and slow so that you don't get a flash for quick operations.
			fadeIn = fadeIn || 1200;
			delay = delay || 0;
			dialog.fadeTo(delay, 0.01).fadeTo(fadeIn, 0.9);
			if (document.activeElement && document.activeElement != document.body ) {
				document.activeElement.blur();
			}
			dialog.findIt("#showWaitFocus").focus();//3723
		}
		function hideWait() {
			var dialog = $("#loading-div-background");
			if (dialog) {
				dialog.remove();
			}
		}
		function getKeyByValue(theMap, mapValue) {
			for (var i in theMap) {
				if (theMap[i] == mapValue)
					return i;
			}
			return null;
		}
		var loadedScripts = {};
		function getScript(scriptUrl, callback, typeofClassName) {
			if (typeofClassName && typeofClassName !== "undefined") callback();
			else if ($("script[src='"+scriptUrl+"'").length > 0) callback();
			else if (loadedScripts[scriptUrl]) callback();
			else {
				activateCaching();
				$.getScript(scriptUrl, function() {
					loadedScripts[scriptUrl] = true;
					if (callback) callback();
				});
			}
		}

		var CallChain = function() {
			var cs = [];
			function add(call) {
				cs.push(call);
			}
			return {
				add: add,
				execute: function(finished) {
					if (finished) add(finished);
					var wrap = function(call, callback) {
						return function() {
							call(callback);
						};
					};
					for (var i = cs.length - 1; i > -1; i--) {
						cs[i] = wrap(cs[i], i < cs.length - 1 ? cs[i + 1] : null);
					}
					cs[0]();
				}
			};
		};
		
		function reloadWhenQuiet() {
			var now = new Date();
	        var tomorrowEarlyMorning = new Date(now.getFullYear(), now.getMonth(), now.getDate()+1, 4, 0, 0);//4am
	        window.setTimeout(function() {
	            window.location.reload();
	        }, tomorrowEarlyMorning-now);
		}
		function arrayRemove(arry, el) {
			if (!arry) {
				return null;
			}
			var index = arry.indexOf(el);
			if (index >= 0) {
				arry.splice(index, 1);
				return el;
			}
			return null;
		}
		
		function waitForElementLoad(tag, callback){
			var interval = setInterval(function (){
				if(document.getElementById(tag) != null){
					clearInterval(interval);
					callback();
				}
			}, 100);
		}
		
		function isInternetExplorer() {
			var uaString = window.navigator.userAgent;
			var match = /\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(uaString);
			if (match) {
				return parseInt(match[2]);
			} else {
				return false;
			}
		}
		function escapeQuotes(str) {
			if (str) {
				return str.replace("'", "\\'").replace('"', '\\"');
			} else {
				return str;
			}
		}
		// stolen from: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
		// Returns a random integer between min (included) and max (excluded)
		// Using Math.round() will give you a non-uniform distribution!
		function getRandomInt(min, max) {
			return Math.floor(Math.random() * (max - min)) + min;
		}
		function uuid() {
			var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
				var r = Math.random() * 16 | 0, v = c == "x" ? r : (r & 0x3 | 0x8);
				return v.toString(16);
			});
			return uuid;
		}
		function storageAvailable(sType) {
			try {
				var storage = window[sType], x = "test";
				storage.setItem(x, x);
				storage.removeItem(x);
				return true;
			} catch(e) {
				return false;
			}
		}
		function display2faInput(okFunction) {
			showInput({
				msg: OTranslate.t("Please generate a code from your authorization app and enter it below.", "OUtils.js.two_factor_auth_code_prompt"),
				dialogTitle: OTranslate.t("Two Factor Authentication", "OUtils.js.two_factor_authentication_title"),
				okText: OTranslate.t("Secure Sign In", "OUtils.js.secure_sign_in_title"),
				okFunction: okFunction
			});
		}
		function regexEscape(str) {
			return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
		}
		/**
		 * Opens the print dialog for a specified URL using an invisible iframe
		 * Taken from: https://developer.mozilla.org/en-US/docs/Web/Guide/Printing#print_an_external_page_without_opening_it
		 * 
		 * @param {String} url URL to print
		 */
		function printUrl(url) {
			function closePrint () {
				document.body.removeChild(this.__container__);
			}
			function setPrint () {
				this.contentWindow.__container__ = this;
				this.contentWindow.onbeforeunload = closePrint;
				this.contentWindow.onafterprint = closePrint;
				this.contentWindow.focus(); // Required for IE
				this.contentWindow.print();
			}
			function printPage (stringUrl) {
				var oHideFrame = document.createElement('iframe');
				oHideFrame.onload = setPrint;
				oHideFrame.style.position = 'fixed';
				oHideFrame.style.right = '0';
				oHideFrame.style.bottom = '0';
				oHideFrame.style.width = '0';
				oHideFrame.style.height = '0';
				oHideFrame.style.border = '0';
				oHideFrame.src = stringUrl;
				document.body.appendChild(oHideFrame);
			}
			printPage(url);
		}

		// polyfills - we can consider moving these to a separate file if they get too big
		if (!String.prototype.endsWith) {
			// endsWith is not supported in IE
			String.prototype.endsWith = function(search, this_len) {
				if (this_len === undefined || this_len > this.length) {
					this_len = this.length;
				}
				return this.substring(this_len - search.length, this_len) === search;
			};
		}
		
		return {	// Public Methods
			cloneTemplate: cloneTemplate,
			getURLVars: getURLVars,
			getAnchorTag: getAnchorTag,
			isLoginPage: isLoginPage,
			dialog: dialog,
			showNote: showNote,
			showError: showError,
			showInput: showInput,
			multiAnswerAsk: multiAnswerAsk,
			confirm: confirm,
			selectText: selectText,
			onKey: onKey,
			onSpace: onSpace,
			onSpaceAndClick: onSpaceAndClick,
			onEnter: onEnter,
			onEscape: onEscape,
			addShortcut: addShortcut,
			promptCancelChanges: promptCancelChanges,
			showOnMouseOver: showOnMouseOver,
			viewEForm: viewEForm,
			menu: menu,
			showPopup: showPopup,
			initializePopups: initializePopups,
			openTabOrRedirectIfNec: openTabOrRedirectIfNec,
			openWindow: openWindow,
			numericOnly: numericOnly,
			upperCaseOnly: upperCaseOnly,
			isLocalTestVersion: isLocalTestVersion,
			loadPage: loadPage,
			loadPageAsPopup: loadPageAsPopup,
			getCookie: getCookie,
			setCookie: setCookie,
			getScript: getScript,
			isBadBrowser: isBadBrowser,
			handleBadBrowser: handleBadBrowser,
			autoDirty: autoDirty,
			getLocalStorage: getLocalStorage,
			setLocalStorage: setLocalStorage,
			getOceanHost: getOceanHost,
			getCloudConnectHost: getCloudConnectHost,
			onMac: onMac,
			showPopupLogin: showPopupLogin,
			showWait: showWait,
			hideWait: hideWait,
			getKeyByValue: getKeyByValue,
			CallChain: CallChain,
			t: OTranslate.t,
			translateHtml: OTranslate.translateHtml,
			i18nInitTest: OTranslate.i18nInitTest,
			i18nInit: OTranslate.i18nInit,
			i18nLoaded: OTranslate.i18nLoaded,
			enableTranslations: OTranslate.enableTranslations,
			reloadWhenQuiet: reloadWhenQuiet,
			waitForElementLoad: waitForElementLoad,
			arrayRemove: arrayRemove,
			needsSignInToContinue: needsSignInToContinue,
			isInternetExplorer: isInternetExplorer,
			activateCaching: activateCaching,
			escapeQuotes: escapeQuotes,
			getRandomInt: getRandomInt,
			storageAvailable: storageAvailable,
			uuid: uuid,
			display2faInput: display2faInput,
			regexEscape: regexEscape,
			getCrlLink: getCrlLink,
			isLocalhost: isLocalhost,
			isStaging: isStaging,
			isAutotest: isAutotest,
			isProd: isProd,
			printUrl: printUrl,
			isListingOtnClaimed: isListingOtnClaimed
		};
	})();
	return OUtils;
});